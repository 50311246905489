import React, { useEffect } from "react"
import Layout, { MyLocationEnum } from '../components/Layout'
import SEO, { MetaOG } from "../components/Seo"

export default ({ location }) => {

  return (
    <Layout location={location} myLocation={MyLocationEnum.Lobby}>
      <SEO title="Hvernig færum við okkur upp í skýið?" />
    </Layout>
  )
}